import React from 'react';
import Card from './Card';

import jobs from '../images/jobs.png';
import profiles from '../images/profiles.png';
import blockchainContract from '../images/blockchain-contract.png';
import payment from '../images/payment.png';

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2 className="title text-center text-4xl mt-12">How it works</h2>
      <div className="cards relative mt-12 md:mx-20 mb-12">
        <div className="bar w-2 rounded-full h-full absolute grey-bg left-0 top-0 hidden md:block"></div>
        <div className="bar w-2 rounded-full h-full absolute grey-bg right-0 top-0 hidden md:block"></div>
        <div className="flex items-center justify-between flex-col md:flex-row">
          <Card title="A company posts a job">
            A company posts a job with a description of the work to do, the
            deadlines and the budget.
            <br />
            The company can wait for candidates to apply and contact freelancers
            directly and tell them about the job
          </Card>
          <img
            src={jobs}
            alt="synergic network jobs"
            width={350}
            className="md:mr-40 mb-12 md:mb-0"
          />
        </div>
        <div className="flex items-center justify-between flex-col md:flex-row">
          <Card title="Freelancers and Agencies apply">
            The freelancers and agencies can apply to any job and discuss with
            the company that posted the job if they are interested as well.
            <br />
            Once they agree on the work details, budget and payment method, they
            can make a contract.
          </Card>
          <img
            src={profiles}
            alt="synergic network profiles"
            width={550}
            className="mb-12 md:mb-0"
          />
        </div>
        <div className="flex items-center justify-between flex-col md:flex-row">
          <Card title="Contract is created on the blockchain">
            Once the company has found the right candidate, they make a contract
            on the blockchain containing all the details of the work that is
            expected, the deadline, the budget and the payment method.
            <br />
            If the candidate accepts, the company funds the contract and the
            work can start.
          </Card>
          <img
            src={blockchainContract}
            alt="synergic network contract on the blockchain"
            width={350}
            className="md:mr-32 mb-12 md:mb-0"
          />
        </div>
        <div className="flex items-center justify-between flex-col md:flex-row">
          <Card title="Work is done, payment is sent">
            Once the work is done and the company accepts the work, the payment
            is released and sent to the freelancer or agency.
            <br />
            Then the contract is either closed or the company and freelancer can
            agree on new work.
          </Card>
          <img
            src={payment}
            alt="synergic network payment"
            width={250}
            className="md:mr-52"
          />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
