import React from 'react';
import illustration from '../images/illustration.png';

const HeadlineComplex = () => {
  return (
    <div className="headline-complex py-8 md:px-12 rounded-3xl gradient-bg flex-col md:flex-row">
      <div className="headline-text">
        <h2 className="title text-4xl">
          Work Contracts secured by the blockchain
        </h2>
        <p className="block mt-6">
          Remove the middleman between freelancers and clients and secure your
          deals with the blockchain.
          <br />
          If you're a company: post a job, find the right freelancer, create a
          contract and secure it with the blockchain and pay with crypto!
          <br />
          If you're a freelancer or agency: find work, secure your contracts on
          the blockchain and get paid in crypto!
        </p>
        <strong className="block mt-6">
          Fees as low as 1% while having the same protection
        </strong>
      </div>
      <img
        src={illustration}
        alt="synergic network illustration"
        className="illustration"
      />
    </div>
  );
};

export default HeadlineComplex;
