import React, { useState } from 'react';
import swal from 'sweetalert';
import Loader from './Loader';

const Join = () => {
  const [email, setEmail] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [isFreelancer, setIsFreelancer] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_SENDINBLUE_API_KEY,
      },
      body: JSON.stringify({
        updateEnabled: false,
        email,
        attributes: {
          TYPE:
            isCompany && isFreelancer
              ? 'Both'
              : isCompany && !isFreelancer
              ? 'Company'
              : 'Freelancer',
        },
      }),
    };

    setLoading(true);

    fetch('https://api.sendinblue.com/v3/contacts', options)
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        swal({
          text: 'Your email is saved, we will contact you soon with updates about the platform!',
          icon: 'success',
        });
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <div className="join md:px-36">
      <h2 className="title text-center text-4xl mt-36">Join the network</h2>
      <form
        onSubmit={onSubmit}
        className="w-full gradient-bg rounded-3xl h-96 mt-12 flex items-center justify-center flex-col"
      >
        <span className="text-xl mb-4">Tell us more about you: </span>
        <label className="inline">
          <input
            className="mr-2"
            name="isCompany"
            type="checkbox"
            checked={isCompany}
            onChange={(e) => setIsCompany(e.target.checked)}
          />
          Company
        </label>
        <label className="inline">
          <input
            className="mr-2"
            name="isFreelancer"
            type="checkbox"
            checked={isFreelancer}
            onChange={(e) => setIsFreelancer(e.target.checked)}
          />
          Freelancer
        </label>
        <input
          type="email"
          placeholder="Email"
          className="w-1/2 input mt-6"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {loading ? (
          <Loader />
        ) : (
          <button className="primary mt-12" type="submit">
            JOIN
          </button>
        )}
      </form>
    </div>
  );
};

export default Join;
