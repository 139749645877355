import React from 'react';

const Card = ({ title, children }) => (
  <div className="card gradient-bg rounded-3xl md:w-1/2 px-6 py-4 md:ml-12">
    <strong className="text-white text-lg block mb-2">{title}</strong>
    <p>{children}</p>
  </div>
);

export default Card;
