import React from 'react';
import logo from '../images/logo100-transparent.png';

const Header = () => {
  return (
    <header className="py-8 px-12 h-24">
      <div className="flex items-center">
        <img src={logo} alt="Synergic Network logo" width={50} />
        <h1 className="bold text-2xl ml-2">Synergic Network</h1>
      </div>
      <nav></nav>
    </header>
  );
};

export default Header;
