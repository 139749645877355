import React from 'react';
import swal from 'sweetalert';

const Security = () => {
  return (
    <div className="security">
      <h2 className="title text-center text-4xl mt-20 md:mt-36">
        Secured transactions, crypto payments, no middleman
      </h2>
      <div className="flex justify-around mt-16 flex-col md:flex-row">
        <div className="grey-bg mb-12 md:mb-0 md:w-1/4 rounded-3xl px-6 py-4 inline-block">
          <strong className="text-xl mb-4 block">
            Be protected in case of dispute
          </strong>
          <p className="mb-4">
            The platform is decentralised but we still offer solutions to
            resolve your disputes.
            <br />
            <br />
            You can signal a dispute and the members of the community will sign
            a NDA, review what's happening, vote for how it should be resolved
            and get rewarded for helping you.
            <br />
            <br />
            Once many people voted, the contract on the blockchain will act
            depending on what people voted.
          </p>
          <button
            onClick={() => {
              swal(
                'In case of a dispute:',
                `The employer or employee can create a dispute and ask for external review to resolve the disagreement.

                  This can happen if:

                      - The client doesn't want to pay the freelancer or
                      agency although the work is done

                      - The freelancer or agency has made a terrible job or
                      the work is not as it should be according to the
                      contract

                      - One of the two parties wants to stop the contract for
                      any reason

                  Members of the community can vote for a resolution of this dispute and get rewarded.
                  Then the contract on the blockchain will act depending on the votes, either pay the freelancer or refund the client.`
              );
            }}
          >
            <b>
              <u>See more</u>
            </b>
          </button>
        </div>
        <div className="grey-bg mb-12 md:mb-0 md:w-1/4 rounded-3xl px-6 py-4 inline-block">
          <strong className="text-xl mb-4 block">
            Pay or get paid in crypto
          </strong>
          <p className="mb-4">
            Clients and freelancers/agencies can agree on a token to use for the
            payment.
            <br />
            <br />
            It can be any token on any of these blockchains: Ethereum, Binance
            Smart Chain, Polygon, Avalanche, Fantom or Solana.
            <br />
            <br />
            More blockchains will be added as the platform grows and we will add
            the ones that the community want.
          </p>
        </div>
        <div className="grey-bg md:w-1/4 rounded-3xl px-6 py-4 inline-block">
          <strong className="text-xl mb-4 block">No service fees</strong>
          <p className="mb-4">
            Unlike other platforms like Upwork or Fiverr, Synergic Network is
            decentralised so we take no service fees
            <br />
            <br />
            Other platforms all take a 20% fee on the payment that the
            freelancer's pay and some also take a fee on the client's budget.
            <br />
            <br />
            We only take 1% to improve the platform according to the decisions
            of the community. We remove the middleman that's why we don't take
            service fees.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Security;
