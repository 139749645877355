import './App.scss';
import Header from './components/Header';
import HeadlineComplex from './components/HeadlineComplex';
import HowItWorks from './components/HowItWorks';
import Join from './components/Join';
import Security from './components/Security';

function App() {
  return (
    <div className="App pb-36">
      <Header />
      <HeadlineComplex />
      <HowItWorks />
      <Security />
      <Join />
    </div>
  );
}

export default App;
